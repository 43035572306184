<template>
  <div
    v-if="state.waqfDetails && !closedWaqfCondition"
    class="donate px-3 px-md-5 mb-5 w-100"
  >
    <div class="donate__container d-flex justify-content-center w-100">
      <DonateCardDetails :payload="state.waqfDetails" type="waqf" />
      <div class="donate__card donate__form col-md-6 section bg-white">
        <h2 class="title fw-bold text-center donate__heading">
          المبلغ المراد التبرع به
        </h2>
        <div class="tags d-flex justify-content-evenly mb-5">
          <div
            :class="{
              'selected-button ': state.donationModel.donation_amount === 10,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(10)"
          >
            10 ر.س
          </div>
          <div
            :class="{
              'selected-button ': state.donationModel.donation_amount === 50,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(50)"
          >
            50 ر.س
          </div>
          <div
            :class="{
              'selected-button ': state.donationModel.donation_amount === 100,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(100)"
          >
            100 ر.س
          </div>
          <div
            :class="{
              'selected-button ':
                state.donationModel.donation_amount ===
                state.waqfDetails.target - state.waqfDetails.balance,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag fw-semibold d-flex align-items-center justify-content-center"
            @click="
              state.donationModel.donation_amount =
                state.waqfDetails.target - state.waqfDetails.balance
            "
          >
            التبرع بالمبلغ المتبقي
          </div>
        </div>
        <div class="w-100 mb-1">
          <BaseInputNumber
            v-model="state.donationModel.donation_amount"
            :disabled="state.disablePaymentFields"
            :placeholder="`أدخل مبلغ التبرع`"
            :class="{
              'p-invalid':
                !state.donationModel.donation_amount && state.isSubmitted,
            }"
            id="donation-amount"
            inputId="donation-amount"
            :max="state.waqfDetails.target - state.waqfDetails.balance"
            :min="1"
          />
        </div>
        <div class="mb-4 donation-disclaimer">
          أعلى قيمة يمكنك التبرع بها
          {{ state.waqfDetails.target - state.waqfDetails.balance }} وأقل قيمة
          يمكنك التبرع بها 1.00
        </div>

        <BaseInputNumber
          :placeholder="'ليصلك اثر تبرعك أدخل رقم جوالك'"
          v-model="state.donationModel.doner_mobile"
          :disabled="state.disablePaymentFields"
          :class="{
            'p-invalid':
              state.donationModel.doner_mobile &&
              !isValidMobileNumber(state.donationModel.doner_mobile),
          }"
          id="doner-mobile"
          :maxLength="10"
          :phoneNumber="true"
          v-if="!$store.getters.isAuthenticated"
        />
        <div class="mb-4">
          <Checkbox
            v-model="state.donationModel.on_behalf_donation"
            :binary="true"
            inputId="on_behalf_donation"
            id="on_behalf_donation"
          />
          <label for="on_behalf_donation" class="mx-2">تبرع عن من تحب</label>
        </div>
        <div v-if="state.donationModel.on_behalf_donation">
          <div class="w-100 mb-4">
            <InputText
              inputId="doner-name"
              id="doner-name"
              placeholder="اسم المتبرع"
              v-model="state.donationModel.on_behalf_donor_name"
              class="w-100"
            />
          </div>
          <div class="w-100 mb-4">
            <BaseInputNumber
              :placeholder="'جوال المهدى له'"
              v-model="state.donationModel.gifted_mobile_number"
              :class="{
                'p-invalid':
                  (state.isSubmitted &&
                    state.donationModel.on_behalf_donation &&
                    !state.donationModel.gifted_mobile_number) ||
                  (state.donationModel.gifted_mobile_number &&
                    !isValidMobileNumber(
                      state.donationModel.gifted_mobile_number
                    )),
              }"
              id="doner-mobile"
              :maxLength="10"
              :phoneNumber="true"
            />
          </div>
        </div>
        <div class="mb-2 color-secondary">وسيلة الدفع</div>
        <div
          class="payment-methods d-flex align-items-center justify-content-center mb-3"
        >
          <div
            class="item d-flex align-items-center justify-content-center"
            @click="state.cardType = 'credit_card'"
            :class="{ selected: state.cardType === 'credit_card' }"
          >
            <img
              class="payment-radio__image payment-radio__image--visa"
              :src="`../../assets/images/card-pay-payment.png`"
              alt="card-payment"
              loading="lazy"
              width="100"
              height="42"
            />
          </div>
          <div v-if="state.browserName === 'safari'">
            <div
              class="apple-pay item d-flex align-items-center justify-content-center"
              @click="applePayDonation"
              :class="{
                selected: state.cardType === 'applepay',
                'p-disabled': disableSubmit,
              }"
            >
              <img
                src="../../assets/images/apple-pay-logo.png"
                alt="apple-pay"
                loading="lazy"
                width="42"
                height="22"
              />
            </div>
          </div>
        </div>
        <div class="row my-5">
          <DonationPrivacyPolicy />
          <BaseSpinner v-if="state.showApplepaySpinner" />
          <Button
            class="donate__button p-button-rounded p-button p-button-primary mt-2 mx-auto justify-content-center"
            @click.stop="donate"
            v-if="state.cardType === 'credit_card'"
            :disabled="disableSubmit"
          >
            <i v-if="state.loading" class="pi pi-spin pi-spinner ms-2"></i>
            <span>تبرع الآن</span>
          </Button>
          <div
            :class="['form-cont', { 'd-none': state.cardType !== 'applepay' }]"
            v-if="
              state.donationModel.donation_amount && state.isHyperpayInjected
            "
          >
            <form
              :action="state.redirectURI"
              class="paymentWidgets"
              :data-brands="'APPLEPAY'"
            ></form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CompletedDonation v-else-if="closedWaqfCondition" donation_type="waqf" />

  <DonationSuccessDialog
    :showModal="state.showDonationSuccessModal"
    :data="state.successDonationData"
    :donationType="state.donationType"
    v-if="state.showDonationSuccessModal"
    @close="state.showDonationSuccessModal = false"
  />

  <DonationFailureDialog
    :showModal="state.showDonationFailureModal"
    :message="state.paymentFailureMessage"
    v-if="state.showDonationFailureModal"
    @close="state.showDonationFailureModal = false"
  />
</template>

<script setup>
import { reactive, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { getLocalValue } from "@/hooks/useLocalStorage";

import DonationService from "@/services/donation.service";
import WaqfService from "@/services/waqf.service";
import { DONATION_TYPE } from "@/services/enum.service";
import { getBrowserEngine } from "@/hooks/useBrowserEnvironmet";
import { useMetaTags } from "@/hooks/useMetaTags";
import { useDonationValidation } from "@/composables/useDonationValidation";

// Components
import DonateCardDetails from "@/components/donate/DonateCardDetails";
import BaseSpinner from "@/components/layout/BaseSpinner";
import CompletedDonation from "@/components/donate/CompletedDonation";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationPrivacyPolicy from "@/components/layout/DonationPrivacyPolicy";

// Initialize composables
const { isValidMobileNumber, validateOnBehalfDonation } =
  useDonationValidation();

const router = useRouter();
const route = useRoute();
const store = useStore();

const state = reactive({
  waqfId: route.params.waqf_id,
  waqfDetails: null,
  donationModel: {
    donation_amount: null,
    doner_mobile: "",
    on_behalf_donor_name: "",
    gifted_to: "",
    gifted_to_text: "",
    donor_relation: "",
    donor_relation_text: "",
    gifted_mobile_number: "",
    on_behalf_donation: false,
  },
  checkoutId: "",
  browserName: getBrowserEngine(),
  cardType: "credit_card",
  redirectURI:
    process.env.VUE_APP_FE_URL +
    `/donate-waqf/${route.params.waqf_id}` +
    (route.query.close ? `?close=${route.query.close}` : ""),
  isSubmitted: false,
  paymentFailureMessage: "",
  showDonationFailureModal: false,
  isHyperpayInjected: false,
  showApplepaySpinner: false,
  resourcePath: "",
  paymentTransactionId: 0,
  showDonationSuccessModal: false,
  successDonationData: {},
  donationType: DONATION_TYPE.CASE,
  disablePaymentFields: false,
  loading: false,
  isAmbassador: false,
  referenceId: route.query.reference_id || null,
});

const getWaqfInfo = async () => {
  try {
    const {
      data: { result },
    } = await WaqfService.getWaqfDetails(state.waqfId);
    state.waqfDetails = result;
    state.waqfDetails.amountPercentage = Math.floor(
      (result.balance / result.target) * 100
    );

    useMetaTags([
      {
        key: "og:description",
        name: "og:description",
        content: state.waqfDetails.description,
        property: "og:description",
      },
      {
        key: "og:image",
        name: "og:image",
        content: state.waqfDetails.image_path,
        property: "og:image",
      },
      {
        key: "twitter:image",
        name: "twitter:image",
        content: state.waqfDetails.image_path,
      },
      {
        key: "twitter:description",
        name: "twitter:description",
        content: state.waqfDetails.description,
      },
    ]);
  } catch (error) {
    console.error("Error fetching waqf details:", error);
  }
};

const donate = async () => {
  state.isSubmitted = true;
  state.loading = true;

  if (
    state.donationModel.on_behalf_donation &&
    !validateOnBehalfDonation(state.donationModel)
  ) {
    state.loading = false;
    return;
  }

  try {
    const postBody = {
      card_type: state.cardType,
      amount: state.donationModel.donation_amount,
      is_waqf_sehi: true,
      object_type: "waqf",
      object_id: state.waqfDetails.id,
      to_wallet: state.waqfDetails.wallet?.id,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
      on_behalf_donor_name: state.donationModel.on_behalf_donor_name || null,
      gifted_to:
        state.donationModel.gifted_to === "أخرى"
          ? state.donationModel.gifted_to_text
          : state.donationModel.gifted_to,
      donor_relation:
        state.donationModel.donor_relation === "أخرى"
          ? state.donationModel.donor_relation_text
          : state.donationModel.donor_relation,
      gifted_mobile_number: state.donationModel.gifted_mobile_number,
      on_behalf_donation: state.donationModel.on_behalf_donation,
      link_of_the_donated_on_object: state.redirectURI,
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(postBody);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    localStorage.setItem(
      "wallet",
      JSON.stringify(state.waqfDetails.wallet?.id)
    );
    injectCheckoutLibrary();
  } catch (error) {
    state.paymentFailureMessage = error.response?.data?.errors?.[0];
    state.showDonationFailureModal = true;
  } finally {
    state.loading = false;
  }
};

const applePayDonation = async () => {
  state.isSubmitted = true;
  state.disablePaymentFields = true;

  if (!state.donationModel.donation_amount) return;

  state.cardType = "applepay";
  if (state.isHyperpayInjected) return;

  state.showApplepaySpinner = true;

  try {
    const postBody = {
      card_type: state.cardType,
      amount: state.donationModel.donation_amount,
      is_waqf_sehi: true,
      object_type: "waqf",
      object_id: state.waqfDetails.id,
      to_wallet: state.waqfDetails.wallet?.id,
      on_behalf_donor_name: state.donationModel.on_behalf_donor_name || null,
      gifted_to:
        state.donationModel.gifted_to === "أخرى"
          ? state.donationModel.gifted_to_text
          : state.donationModel.gifted_to,
      donor_relation:
        state.donationModel.donor_relation === "أخرى"
          ? state.donationModel.donor_relation_text
          : state.donationModel.donor_relation,
      gifted_mobile_number: state.donationModel.gifted_mobile_number,
      on_behalf_donation: state.donationModel.on_behalf_donation,
      link_of_the_donated_on_object: state.redirectURI,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(postBody);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    localStorage.setItem(
      "wallet",
      JSON.stringify(state.waqfDetails.wallet?.id)
    );
    injectCheckoutLibrary();
  } catch (error) {
    state.paymentFailureMessage = error.response?.data?.errors?.[0];
    state.showDonationFailureModal = true;
    state.showApplepaySpinner = false;
  } finally {
    state.loading = false;
  }
};

const setDonationAmount = (amount) => {
  if (
    amount >
    state.waqfDetails.required_amount - state.waqfDetails.collected_amount
  ) {
    state.donationModel.donation_amount =
      state.waqfDetails.required_amount - state.waqfDetails.collected_amount;
  } else {
    state.donationModel.donation_amount = amount;
  }
};

const injectCheckoutLibrary = () => {
  const paymentLibraryScript = document.createElement("script");
  paymentLibraryScript.setAttribute(
    "src",
    `${process.env.VUE_APP_HYPERPAY_URL}/v1/paymentWidgets.js?checkoutId=${state.checkoutId}`
  );
  document.head.appendChild(paymentLibraryScript);

  state.isHyperpayInjected = true;
  setTimeout(() => {
    state.showApplepaySpinner = false;
  }, 300);

  if (state.cardType === "applepay") return;

  router.push(
    `/checkout?card_type=${state.cardType}&donation_amount=${state.donationModel.donation_amount}`
  );
};

const checkPaymentStatus = async () => {
  try {
    await DonationService.getPaymentStatus(
      state.paymentTransactionId,
      state.resourcePath
    );
    const donationModel = JSON.parse(localStorage.getItem("donationModel"));

    const postBody = {
      to_wallet: JSON.parse(localStorage.getItem("wallet")),
      amount: donationModel.donation_amount,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
      checkout_id: state.paymentTransactionId,
      on_behalf_donor_name: donationModel.on_behalf_donor_name || null,
      gifted_to:
        donationModel.gifted_to === "أخرى"
          ? donationModel.gifted_to_text
          : donationModel.gifted_to,
      donor_relation:
        donationModel.donor_relation === "أخرى"
          ? donationModel.donor_relation_text
          : donationModel.donor_relation,
      gifted_mobile_number: donationModel.gifted_mobile_number,
      on_behalf_donation: donationModel.on_behalf_donation,
      link_of_the_donated_on_object: state.redirectURI,
    };

    const {
      data: {
        result: { donation_success_data },
      },
    } = await DonationService.donate(postBody);
    state.successDonationData = donation_success_data;
    state.donationType = donation_success_data.type;
    state.showDonationSuccessModal = true;
  } catch (error) {
    state.paymentFailureMessage = error.response?.data?.errors?.[0];
    state.showDonationFailureModal = true;
  } finally {
    localStorage.removeItem("donationModel");
    localStorage.removeItem("wallet");
  }
};

// Computed properties
const closedWaqfCondition = computed(
  () => state.waqfDetails?.is_completed || state.waqfDetails?.is_cancelled
);

const disableSubmit = computed(
  () =>
    !state.donationModel.donation_amount ||
    (state.donationModel.doner_mobile &&
      !isValidMobileNumber(state.donationModel.doner_mobile)) ||
    state.loading
);

// Initialize
getWaqfInfo();

if (route.query.id && route.query.resourcePath) {
  state.resourcePath = route.query.resourcePath;
  state.paymentTransactionId = route.query.id;
  checkPaymentStatus();
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/donate";
</style>
