<template>
  <Card class="card-board__item case-card-item d-flex flex-column justify-content-between" @click="gotoDonateCase(case_.id, close)" data-cy="case-card">
    <template #header>
      <div class="position-relative">
        <VLazyImage loading="lazy" class="condition-img"
             :src="(case_.urls?.case_image && case_.urls?.case_image[0]) || case_.urls?.id_photo"
             alt="صورة الحالة" data-cy="case-image" width="300" height="150"/>
             <div :class="{ 'overlay position-absolute bg-black': calculatePercentage >= 100 }"></div>
          <div class="charity-image position-absolute">
          <VLazyImage class="charity-image__hex" :src="require('@/assets/icons/hex.svg')" alt="صورة الجمعية" width="50" height="57" />
          <VLazyImage class="charity-image__charity position-absolute start-0 h-100" :src="case_.urls?.charity_image" alt="صورة الجمعية" data-cy="charity-logo" width="50" height="57"/>
        </div>
        <div v-if="case_.remaining_period" data-cy="remaining-period"
          class="remaining-period px-3 py-1 position-absolute d-flex align-items-center justify-content-center opacity-50 bg-black rounded-5 text-white">
          <div class="pi pi-clock px-2"></div>
          <div class="pb-1">تنتهي بعد {{case_.remaining_period}} يوم</div>
        </div>
      </div>
      <div class="remaining_amount_card">
        <div v-if="calculatePercentage >= 100" class="d-flex justify-content-center align-items-center p-3 color-primary">
          <div class="completed-icon"></div>
          <div class="">بعطائكم اكتملت فرصة التبرع</div>
        </div>
        <div v-else class="percentage-card pointer" data-cy="remaining-amount-value">
          <span>المتبقي <span> {{ (case_.required_amount - case_.balance).toLocaleString("en-US") }} </span> ريال</span>
          <div class="progress mt-3" data-cy="remaining-amount-progress-bar">
            <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                 :style="{width: calculatePercentage <= 100 ? calculatePercentage + '%' : '100%'}"
                 aria-valuemax="100" aria-label="completed ratio">
              <span v-if="calculatePercentage >= 20" class="progress-bar__percentage">{{calculatePercentage <= 100 ? calculatePercentage + '%' : '100%'}}</span>
            </div>
            <div class="hint" style="right: 9%">
              {{ Math.trunc((case_.balance / case_.required_amount) * 100) }}%
            </div>
          </div>
        </div>
        <div class="remaining_amount_card__details">
          <span data-cy="case-id">
            <span class="color-complementary">#</span>
            {{ case_.id }}
          </span>
          <span class="color-complementary">تفاصيل الحالة</span>
        </div>
      </div>
    </template>
    <template #content>
      <div class="mt-3 px-3">
        <div class="row">
          <div class="fw-bold title" :title="case_.story_title" data-cy="case-title">
            {{ case_.story_title }}
          </div>
          <div class="condition-description mt-2" :title="case_.story" data-cy="case-description">
            {{ case_.story }}
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="details px-2">
        <div class="d-flex align-items-center pt-2 pb-2">
          <div class="d-flex justify-content-start align-items-center">
            <div>
              <i class="pi pi-map-marker color-primary mx-2"></i>
            </div>
            <div>
              <span class="fw-semibold" data-cy="area-name">{{ case_.area_name }}</span>
            </div>
          </div>
          <div class="col-6 text-center d-flex justify-content-center align-items-center">
            <div>
              <VLazyImage class="speciality-icon mx-2" alt="speciality icon"
                   :src="case_.specialty_image"
                   v-if="case_.specialty_image"
                   width="18" height="18"/>
              <VLazyImage class="speciality-icon mx-2" alt="speciality icon"
                   :src="require('../../assets/icons/generic-case-icon.svg')"
                   width="18" height="18"
                   v-else/>
            </div>
            <div>
              <span class="fw-semibold" data-cy="speciality-name">{{ case_.specialty_name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="cta d-flex justify-content-between pt-4">
        <template v-if="case_.can_donate && canDonate" class="w-100">
          <Button class="p-button-primary w-100 close-btn"
                  :label="'اقفل الحالة'"
                  :disabled="!case_.can_donate || !canDonate"
                  data-cy="case-closed-button"
                  icon="pi pi-check-circle" v-if="close" 
                  @click="gotoDonateCase(case_.id, close)"
          />

          <Button class="p-button-primary w-100"
                  :label="'تبرع الآن'"
                  :disabled="!case_.can_donate || !canDonate"
                  data-cy="donate-now-button"
                  @click="gotoDonateCase(case_.id, close)"
                  v-if="!close" />
        </template>
        <span v-else-if="calculatePercentage >= 100" class="d-flex align-items-center justify-content-center w-100">
          مكتملة
        </span>
        <span v-else class="d-flex align-items-center justify-content-center w-100">
          تبرع الآن
        </span>
        <DonorCaseShareIcon
          class="me-3 h-100"
          :sharable-link="sharableLink"
          header="ساهم في العلاج عن طريق مشاركتك الحالة للآخرين"
          :authenticated="!$store.getters.isAuthenticated"
          variant="legacy"
          aria-label="share case"
          @open="openShareDialog"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import DonorCaseShareIcon from '@/components/campaigns/DonorCaseShareIcon';
import BaseSpinner from "@/components/layout/BaseSpinner.vue";
import JwtUtilService from "@/services/jwt.util.service";
import AmbassadorService from "@/services/ambassador.service";
import VLazyImage from "v-lazy-image";
import { setLocalValue } from "@/hooks/useLocalStorage"
import { useRouter } from 'vue-router';

export default {
  name: "case-card",
  components: { BaseSpinner, VLazyImage, DonorCaseShareIcon },
  props: {
    case_: {},
    close: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      sharableLink: `${process.env.VUE_APP_API_URL}/share/${this.$props.case_?.id}?object_type=case`,
      canDonate: JwtUtilService.getClientID()?.includes("doner") || !this.$store.getters.isAuthenticated,
      canShareTrackableLink: JwtUtilService.getClientID()?.includes("doner") && this.$store.getters.isAuthenticated,
      PREVIEWURL: process.env.VUE_APP_API_URL+'/share',
      loading: false,
      router: useRouter()
    };
  },
  mounted() {
    this.addRemainingPeriod();
  },
  watch: {
    case_(val) {
      if (val) {
        this.addRemainingPeriod();
        this.sharableLink = `${process.env.VUE_APP_API_URL}/share/${val.id}?object_type=case`;
      }
    },
  },
  methods: {
    openShareDialog() {
      if (this.canShareTrackableLink) {
        this.createLink();
      }
    },
    async createLink() {
      this.loading = true;
      await AmbassadorService.postCaseNumber({case: this.case_?.id}).then((response) => {
        const result = response.data.result;
        this.sharableLink = `${this.PREVIEWURL}/${result.case}?object_type=case&link_id=${result.id}`;
        this.loading = false;
      });
    },
    addRemainingPeriod() {
      let caseEndDate = new Date(this.$props.case_?.published_on).setDate(new Date(this.$props.case_?.published_on).getDate() + this.$props.case_?.publish_duration);
      let remaining_period_in_days = Math.ceil((new Date(caseEndDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
      this.$props.case_.remaining_period = remaining_period_in_days > 0 ? remaining_period_in_days : 0;
    },
    gotoDonateCase(id, close) {
      setLocalValue('selected_case_id', this.$props.case_.id);
      this.router.push({ name: 'donate-case', params: { case_id: id }, query: {close: close} });
    }
  },
  computed: {
    calculatePercentage() {
      return Math.trunc((this.case_.balance / this.case_.required_amount) * 100);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/case-card";

::v-deep(.p-card-footer) {
  padding: 0  0 20px !important;
}
.overlay {
    inset: 0;
    opacity: 1;
    mix-blend-mode: color;
  }
.charity-image {
  &__hex {
    opacity: .4;
    width:50px;
    height: 50px;
  }
  &__charity {
    width: 35px;
    height: 35px;
    top:7.5px;
    right:7.5px;
  }
}
.remaining_amount_card
{
  font-size: 14px;
  width: 90%;
}
</style>
