<template>
  <div
    v-if="state.caseDetails && !closedCasesCondition"
    class="donate px-3 px-md-5 mb-5 w-100"
  >
    <div class="donate__container d-flex justify-content-center w-100">
      <DonateCardDetails :payload="state.caseDetails" type="case" />
      <div class="donate__card donate__form col-md-6 section bg-white">
        <h2
          class="title fw-bold text-center donate__heading"
          data-cy="donate-case-card-header"
        >
          المبلغ المراد التبرع به
        </h2>
        <div class="tags d-flex justify-content-evenly mb-5">
          <div
            :class="{
              'selected-button': state.donationModel.donation_amount === 10,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(10)"
            data-cy="donate-10-sar"
          >
            10 ر.س
          </div>
          <div
            :class="{
              'selected-button': state.donationModel.donation_amount === 50,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(50)"
            data-cy="donate-50-sar"
          >
            50 ر.س
          </div>
          <div
            :class="{
              'selected-button': state.donationModel.donation_amount === 100,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            @click="setDonationAmount(100)"
            data-cy="donate-100-sar"
          >
            100 ر.س
          </div>
          <div
            :class="{
              'selected-button':
                state.donationModel.donation_amount ===
                state.caseDetails.required_amount -
                  state.caseDetails.collected_amount,
              'p-disabled': state.disablePaymentFields,
            }"
            class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center"
            data-cy="donate-remaining-amount"
            @click="
              state.donationModel.donation_amount =
                state.caseDetails.required_amount -
                state.caseDetails.collected_amount
            "
          >
            التبرع بالمبلغ المتبقي
          </div>
        </div>
        <div class="w-100 mb-1">
          <BaseInputNumber
            v-model="state.donationModel.donation_amount"
            :disabled="state.disablePaymentFields"
            :min="1"
            :max="
              state.caseDetails.required_amount -
              state.caseDetails.collected_amount
            "
            :placeholder="`أدخل مبلغ التبرع`"
            :class="{
              'p-invalid':
                !state.donationModel.donation_amount && state.isSubmitted,
            }"
            id="donation-amount"
            inputId="donation-amount"
            data-cy="donation-amount"
          />
        </div>
        <div class="mb-4 donation-disclaimer">
          أعلى قيمة يمكنك التبرع بها {{ maximumDonationAmount }} وأقل قيمة يمكنك
          التبرع بها 1.00
        </div>
        <BaseInputNumber
          :placeholder="'ليصلك اثر تبرعك أدخل رقم جوالك'"
          v-model="state.donationModel.doner_mobile"
          :disabled="state.disablePaymentFields"
          :class="{
            'p-invalid':
              state.donationModel.doner_mobile &&
              !isValidMobileNumber(state.donationModel.doner_mobile),
          }"
          id="doner-mobile"
          :maxLength="10"
          :phoneNumber="true"
          v-if="!$store.getters.isAuthenticated"
        />
        <div class="mb-4 d-flex align-items-center justify-content-between">
          <div>
            <Checkbox
              v-model="state.donationModel.on_behalf_donation"
              :binary="true"
              inputId="on_behalf_donation"
              data-cy="on-behalf-donation-checkbox"
              id="on_behalf_donation"
            />
            <label for="on_behalf_donation" class="mx-2">تبرع عن من تحب</label>
          </div>
          <template v-if="state.donationModel.on_behalf_donation">
            <button
              class="p-button p-button-primary p-button-rounded p-button-outlined d-flex flex-column p-1 mt-2 border-0"
              style="text-decoration: none"
              @click="openPreviewSMS"
            >
              <i class="pi pi-eye fs-3"></i>
              <div class="px-2">معاينة الرسالة</div>
            </button>
          </template>
        </div>
        <div v-if="state.donationModel.on_behalf_donation">
          <div class="w-100 mb-4">
            <InputText
              inputId="doner-name"
              data-cy="donor-name"
              id="doner-name"
              placeholder="اسم المتبرع"
              v-model="state.donationModel.on_behalf_donor_name"
              class="w-100"
            />
          </div>
          <div
            class="w-100 mb-4"
            v-if="state.donationModel.gifted_to === 'أخرى'"
          >
            <InputText
              inputId="giftedToText"
              id="giftedToText"
              data-cy="gifted-to-text"
              placeholder="إهداء إلى"
              v-model="state.donationModel.gifted_to_text"
              class="w-100"
              :class="{
                'p-invalid':
                  state.isSubmitted &&
                  state.donationModel.gifted_to === 'أخرى' &&
                  !state.donationModel.gifted_to_text,
              }"
            />
          </div>
          <div class="w-100 mb-4">
            <BaseInputNumber
              :placeholder="'جوال المهدى له'"
              v-model="state.donationModel.gifted_mobile_number"
              :class="{
                'p-invalid':
                  (state.isSubmitted &&
                    state.donationModel.on_behalf_donation &&
                    !state.donationModel.gifted_mobile_number) ||
                  (state.donationModel.gifted_mobile_number &&
                    !isValidMobileNumber(
                      state.donationModel.gifted_mobile_number
                    )),
              }"
              id="mobile-number"
              data-cy="gifted-mobile-number"
              :maxLength="10"
              :phoneNumber="true"
            />
          </div>
          <div
            class="w-100 mb-4"
            v-if="state.donationModel.donor_relation === 'أخرى'"
          >
            <InputText
              inputId="donor_relation_Text"
              id="donor_relation_Text"
              data-cy="donor-relation-text"
              placeholder="توقيع المٌهدي"
              v-model="state.donationModel.donor_relation_text"
              class="w-100"
              :class="{
                'p-invalid':
                  state.isSubmitted &&
                  state.donationModel.donor_relation === 'أخرى' &&
                  !state.donationModel.donor_relation_text,
              }"
            />
          </div>
        </div>
        <div class="mb-2 color-secondary">وسيلة الدفع</div>
        <div
          class="payment-methods d-flex align-items-center justify-content-center mb-3"
        >
          <div
            class="item d-flex align-items-center justify-content-center"
            @click="state.cardType = 'credit_card'"
            :class="{ selected: state.cardType === 'credit_card' }"
          >
            <img
              class="payment-radio__image payment-radio__image--visa"
              loading="lazy"
              :src="`../../assets/images/card-pay-payment.png`"
              alt=""
              data-cy="pay-by-credit-card"
            />
          </div>
          <div v-if="state.browserName === 'safari'">
            <div
              class="apple-pay item d-flex align-items-center justify-content-center"
              @click="applePayDonation"
              :class="{
                selected: state.cardType === 'applepay',
                'p-disabled': disableSubmit,
              }"
            >
              <img
                data-cy="pay-by-apple-pay"
                loading="lazy"
                :src="`../../assets/images/apple-pay-logo.png`"
                alt="apple-pay"
              />
            </div>
          </div>
        </div>
        <div class="row my-5">
          <DonationProvacyPolicy pre-link-text="بإتمام التبرع أنت موافق على" />
          <BaseSpinner v-if="state.showApplepaySpinner" />
          <Button
            class="donate__button p-button-rounded p-button p-button-primary mt-2 mx-auto justify-content-center"
            @click.stop="donate"
            data-cy="donate-button"
            :disabled="disableSubmit"
            v-if="state.cardType === 'credit_card'"
          >
            <i v-if="state.loading" class="pi pi-spin pi-spinner ms-2"></i>
            <span>تبرع الآن</span>
          </Button>
          <div
            :class="['form-cont', { 'd-none': state.cardType !== 'applepay' }]"
            v-if="
              state.donationModel.donation_amount && state.isHyperpayInjected
            "
          >
            <form
              :action="state.redirectURI"
              class="paymentWidgets"
              :data-brands="'APPLEPAY'"
            ></form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CompletedDonation v-else-if="closedCasesCondition" donation_type="case" />

  <PreviewSMSDialog
    :showModal="state.showPreviewSMS"
    @close="closePreviewSMS"
    :previewImageLink="state.previewImageLink"
  />

  <DonationSuccessDialog
    :showModal="state.showDonationSuccessModal"
    :data="state.successDonationData"
    :donationType="state.donationType"
    v-if="state.showDonationSuccessModal"
    @close="state.showDonationSuccessModal = false"
  />

  <DonationFailureDialog
    :showModal="state.showDonationFailureModal"
    :message="state.paymentFailureMessage"
    v-if="state.showDonationFailureModal"
    @close="state.showDonationFailureModal = false"
  />
</template>

<script setup>
import { reactive, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import DonationService from "@/services/donation.service";
import { getBrowserEngine } from "@/hooks/useBrowserEnvironmet";
import { getLocalValue } from "@/hooks/useLocalStorage";
import { DONATION_TYPE } from "@/services/enum.service";
import MediaService from "@/services/media.service";
import { useDonationValidation } from "@/composables/useDonationValidation";

// Components
import DonateCardDetails from "@/components/donate/DonateCardDetails";
import BaseSpinner from "@/components/layout/BaseSpinner";
import CompletedDonation from "@/components/donate/CompletedDonation";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationProvacyPolicy from "@/components/layout/DonationPrivacyPolicy";
import PreviewSMSDialog from "@/components/donate/PreviewSMSDialog";

const route = useRoute();
const router = useRouter();

const { isValidMobileNumber, validateOnBehalfDonation } =
  useDonationValidation();

const state = reactive({
  caseId: route.params.case_id,
  caseDetails: null,
  donationModel: {
    donation_amount: null,
    doner_mobile: "",
    on_behalf_donor_name: "",
    gifted_to: "",
    gifted_to_text: "",
    donor_relation: "",
    donor_relation_text: "",
    gifted_mobile_number: "",
    on_behalf_donation: false,
  },
  checkoutId: "",
  browserName: getBrowserEngine(),
  cardType: "credit_card",
  redirectURI:
    process.env.VUE_APP_FE_URL +
    `/donate-case/${route.params.case_id}` +
    (route.query.close ? `?close=${route.query.close}` : ""),
  isAmbassador: false,
  referenceId: route.query.reference_id || null,
  isSubmitted: false,
  paymentFailureMessage: "",
  showDonationFailureModal: false,
  isHyperpayInjected: false,
  showApplepaySpinner: false,
  showDonationSuccessModal: false,
  successDonationData: {},
  donationType: DONATION_TYPE.CASE,
  resourcePath: "",
  paymentTransactionId: 0,
  disablePaymentFields: false,
  loading: false,
  showPreviewSMS: false,
  previewImageLink: "",
});

const openPreviewSMS = async () => {
  const { data: { result } = {} } = await MediaService.getSmsPreview();
  state.previewImageLink = result;
  state.showPreviewSMS = true;
};

const closePreviewSMS = () => {
  state.showPreviewSMS = false;
};

const getCaseInfo = async () => {
  if (state.caseId === undefined) return;

  try {
    const {
      data: { result },
    } = await DonationService.getCaseDetails(state.caseId, state.referenceId);

    state.caseDetails = result;

    useHead({
      meta: [
        {
          name: "og:description",
          content: state.caseDetails.story,
        },
        {
          name: "og:image",
          content:
            state.caseDetails.urls.case_image[0] ||
            state.caseDetails.urls.charity_image,
        },
        {
          name: "twitter:image",
          content: state.caseDetails.urls.case_image[0],
        },
        {
          name: "twitter:description",
          content: state.caseDetails.story,
        },
      ],
    });

    state.caseDetails.remainingAmount =
      state.caseDetails.required_amount - state.caseDetails.collected_amount;
    state.caseDetails.amountPercentage = Math.floor(
      (state.caseDetails.collected_amount / state.caseDetails.required_amount) *
        100
    );

    if (route.query.close === "true") {
      state.donationModel.donation_amount =
        state.caseDetails.required_amount - state.caseDetails.collected_amount;
    }
  } catch (error) {
    console.error("Error fetching case details:", error);
  }
};

const donate = async () => {
  state.loading = true;
  state.isSubmitted = true;

  if (
    state.donationModel.on_behalf_donation &&
    !validateOnBehalfDonation(state.donationModel)
  ) {
    state.loading = false;
    return;
  }

  try {
    if (!state.isAmbassador) {
      const checkoutPayload = {
        amount: state.donationModel.donation_amount,
        card_type: state.cardType,
        object_id: state.caseDetails.id,
        object_type: "case",
        gifted_mobile_number: state.donationModel.on_behalf_donation
          ? state.donationModel.gifted_mobile_number
          : state.donationModel.doner_mobile,
        target: state.caseDetails.required_amount,
        to_wallet: state.caseDetails.wallet,
        link_of_the_donated_on_object: state.redirectURI,
        donor_mobile_number: getLocalValue("donor_mobile_number"),
      };

      const {
        data: { result: checkout_id },
      } = await DonationService.getPaymentId(checkoutPayload);
      state.checkoutId = checkout_id?.checkout_id;
      localStorage.setItem(
        "donationModel",
        JSON.stringify(state.donationModel)
      );
      localStorage.setItem("wallet", JSON.stringify(state.caseDetails.wallet));
      injectCheckoutLibrary();
    } else {
      const postBody = {
        amount: state.donationModel.donation_amount,
        card_type: state.cardType,
        object_id: state.caseDetails.id,
        object_type: "case",
        gifted_mobile_number: state.donationModel.on_behalf_donation
          ? state.donationModel.gifted_mobile_number
          : state.donationModel.doner_mobile,
        target: state.caseDetails.required_amount,
        to_wallet: state.caseDetails.wallet,
        link_of_the_donated_on_object: state.redirectURI,
        donor_mobile_number: getLocalValue("donor_mobile_number"),
      };

      const {
        data: { result: checkout_id },
      } = await DonationService.getAmbassadorPaymentId(
        postBody,
        state.referenceId
      );
      state.checkoutId = checkout_id?.checkout_id;
      localStorage.setItem(
        "donationModel",
        JSON.stringify(state.donationModel)
      );
      localStorage.setItem("wallet", JSON.stringify(state.caseDetails.wallet));
      injectCheckoutLibrary();
    }
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  } finally {
    state.loading = false;
  }
};

const applePayDonation = async () => {
  state.isSubmitted = true;
  state.disablePaymentFields = true;

  if (
    !state.donationModel.donation_amount ||
    (state.donationModel.on_behalf_donation &&
      !validateOnBehalfDonation(state.donationModel))
  ) {
    return;
  }

  state.cardType = "applepay";
  if (state.isHyperpayInjected) return;

  state.showApplepaySpinner = true;

  try {
    const checkoutPayload = {
      amount: state.donationModel.donation_amount,
      card_type: state.cardType,
      object_id: state.caseDetails.id,
      object_type: "case",
      target: state.caseDetails.required_amount,
      to_wallet: state.caseDetails.wallet,
      link_of_the_donated_on_object: state.redirectURI,
      donor_mobile_number: getLocalValue("donor_mobile_number"),
    };

    const {
      data: { result: checkout_id },
    } = await DonationService.getPaymentId(checkoutPayload, state.referenceId);
    state.checkoutId = checkout_id?.checkout_id;
    localStorage.setItem("donationModel", JSON.stringify(state.donationModel));
    localStorage.setItem("wallet", JSON.stringify(state.caseDetails.wallet));
    injectCheckoutLibrary();
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
    state.showApplepaySpinner = false;
  }
};

const injectCheckoutLibrary = () => {
  const paymentLibraryScript = document.createElement("script");
  paymentLibraryScript.setAttribute(
    "src",
    `${process.env.VUE_APP_HYPERPAY_URL}/v1/paymentWidgets.js?checkoutId=${state.checkoutId}`
  );
  document.head.appendChild(paymentLibraryScript);

  state.isHyperpayInjected = true;
  setTimeout(() => {
    state.showApplepaySpinner = false;
  }, 300);

  if (state.cardType === "applepay") return;

  router.push(
    `/checkout?card_type=${state.cardType}&donation_amount=${state.donationModel.donation_amount}`
  );
};

const checkPaymentStatus = async () => {
  try {
    const { data } = await DonationService.getPaymentStatus(
      state.paymentTransactionId,
      state.resourcePath
    );

    if (data.status === 200 && data.donation_success_data) {
      state.successDonationData = data.donation_success_data;
      state.donationType = data.donation_success_data.type;
      state.showDonationSuccessModal = true;
    }
  } catch (error) {
    if (error?.response?.data?.errors?.length > 0) {
      state.paymentFailureMessage = error.response.data.errors[0];
    } else if (error?.response?.data?.message) {
      state.paymentFailureMessage = error.response.data.message;
    } else if (error?.message) {
      state.paymentFailureMessage = error.message;
    }
    state.showDonationFailureModal = true;
  } finally {
    localStorage.removeItem("donationModel");
    localStorage.removeItem("wallet");
  }
};

const setDonationAmount = (amount) => {
  if (
    amount >
    state.caseDetails.required_amount - state.caseDetails.collected_amount
  ) {
    state.donationModel.donation_amount =
      state.caseDetails.required_amount - state.caseDetails.collected_amount;
  } else {
    state.donationModel.donation_amount = amount;
  }
};

// Computed properties
const closedCasesCondition = computed(
  () => state.caseDetails?.is_completed || state.caseDetails?.is_cancelled
);

const disableSubmit = computed(
  () =>
    !state.donationModel.donation_amount ||
    (state.donationModel.doner_mobile &&
      !isValidMobileNumber(state.donationModel.doner_mobile)) ||
    state.loading
);

const maximumDonationAmount = computed(() =>
  Math.ceil(
    state.caseDetails.required_amount - state.caseDetails.collected_amount
  ).toLocaleString("en-US")
);

// Initialize
getCaseInfo();

if (route.query.id && route.query.resourcePath) {
  state.resourcePath = route.query.resourcePath;
  state.paymentTransactionId = route.query.id;
  checkPaymentStatus();
}

// Watch
watch(
  () => route.params.case_id,
  () => {
    state.caseId = route.params.case_id;
    getCaseInfo();
  }
);
</script>

<style lang="scss" scoped>
@import "@/styles/components/donate";
</style>
